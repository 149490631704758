window.equipmentSelectFilter = function(el){
  var name = el.name.split('[')[1].split('_')[0].replace(']', '');
  if(name == 'product'){
    name = 'speed';
  }
  propValue1 = [];
  len = el.selectedOptions.length;
  thisForm = el.closest('form');
  if(thisForm != null){
    propContainers = thisForm.querySelectorAll('.eq-prop-container');
    if(len > 0){
      for(var i=0; i<len; i++){
        opName = el.selectedOptions[i].innerText;
        if(name == 'color'){
          opName = opName.split(' ')[0];
        }
        propValue1.push(opName);
      }
      propValue2 = propValue1.join(', ');
      if(propContainers != null){
        propContainers.forEach(cont => {
          createOrChangePropBox(cont, name, propValue2);
        });
      }
    }else{
      if(propContainers != null){
        propContainers.forEach(cont => {
          prop = cont.querySelector(`#${name}`);
          cont.removeChild(prop);
        });
      }
    }
  }
}

window.toggleSelectedAddons = function(el){
  len = 0;
  const name = el.id;
  if(el.checked){ len = 1; }
  thisModalDiv = el.closest('.getting-quote-modal');
  propContainers = thisModalDiv.querySelectorAll('.eq-prop-container');
  if(len > 0){
    if(propContainers != null){
      propContainers.forEach(cont => {
        createOrChangePropBox(cont, name, '1');
      });
    }
  }else{
    if(propContainers != null){
      propContainers.forEach(cont => {
        prop = cont.querySelector(`#${name}`);
        cont.removeChild(prop);
      });
    }
  }
}

// quote select your equipment window,
// prevent user from moving next modal or widow when not selecting any filter
window.checkQuoteFilters = function(el, notModal, isNextBtn){
  thisWin = el.closest('.getting-quote-modal');
  colorTypes = thisWin.querySelector('input[name="order[color_types][]"]');
  brands = thisWin.querySelector('input[name="order[brand_ids][]"]');
  speeds = thisWin.querySelector('input[name="order[product_speeds][]"]');
  conditions = thisWin.querySelector('input[name="order[conditions][]');
  // inputs = thisWin.querySelectorAll('input[name^="order"]');
  prevent = isEmpty([colorTypes, brands, speeds, conditions]);
  if(isNextBtn){
    prevent = prevent && isEmptyEqCardsContainer(thisWin);
  }
  if(prevent){
    event.preventDefault();
    console.log('invalid');
    alert('Please select at least one filter!');
    return false;
  }
  else{
    if(!notModal){
      var targetModal = $(el).data('target');
      modal = document.querySelector(targetModal);
      $(modal)[0].style.zIndex = 1056;
      $(modal).modal('show');

      //hide this modal
      thisModal = event.target.closest('.modal');
      $(thisModal)[0].style.zIndex = 1056;
      $(thisModal).modal('hide');
    }
    return true;
  }
}

// return true if any of the four filters is selected
function isEmpty(iNs){
  for (let i = 0; i < iNs.length; i++) {
    let inp = iNs[i];
    selectBox = inp.parentElement.querySelector('select');
    notEmpty = true;
    if (selectBox != null) {
      console.log(selectBox.selectedOptions);
      if (selectBox.selectedOptions.length != 0) {
          console.log('valid');
          notEmpty = false;
          break; // exit the loop if there is a single filter selcted
      }
    }
  }
  return notEmpty;
}

// returns false when there is already an equipment added
function isEmptyEqCardsContainer(thisWin){
  eqContain = thisWin.querySelector('#eq-card-container');
  if(eqContain.childElementCount > 0){
    return false;
  }
  return true;
}

function createOrChangePropBox(container, name, value){
  prop = container.querySelector(`#${name}`);
  if(prop == null){
    propDiv = document.createElement('div');
    propDiv.className = 'eq-prop';
    propDiv.id = name;
    propName = document.createElement('span');
    propName.className = 'prop-name';
    propName.innerText = `${capitalizeFirstLetter(name)}: `;
    propVale = document.createElement('span');
    propVale.className = 'prop-value';
    propVale.innerText = value;
    propDiv.appendChild(propName);
    propDiv.appendChild(propVale);
    container.appendChild(propDiv);
  }else{
    prop.querySelector('.prop-name').innerText = `${capitalizeFirstLetter(name)}: `;
    prop.querySelector('.prop-value').innerText = value;
  }
}

function capitalizeFirstLetter(str) {
  return str.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
}

// To Open Addon Info modals on top of quote modals
window.showInfoModal = function(el){
  var targetModal = $(el).data('target');
  $(targetModal)[0].style.zIndex = 1056;
  $(targetModal).modal('show');
}

window.openNextAcord = function(el, next){
  let nextBtn = next;
  let thisBtn = null;
  if(el != null){
    thisBtn = el.closest('.accordion-item-quote').querySelector('.accordion-button');
  }
  // close thisBtn
  if(thisBtn != null){
    if(!thisBtn.classList.contains('collapsed')){
      $(thisBtn).click();
      thisBtn.innerText = thisBtn.dataset.innertxt; 
      thisBtn.classList.add('pb-4');
    }
  }
  // open nextBtn
  if(nextBtn.classList.contains('collapsed')){
    $(nextBtn).click();
  }
  if(!nextBtn.classList.contains('collapsed')){
    nextBtn.innerText = ''; nextBtn.classList.remove('pb-4');
  }
}

// Open first accordian
window.openFirstAcord = function(){
  setTimeout(function() {
    let mainAccord = document.querySelector('#panelsStayOpenLockedQuotePWin-headingFive').querySelector('.accordion-button');
    openNextAcord(null, mainAccord);
    mainAccord.closest('.quote-accords-main').querySelectorAll('.accordion-item-quote').forEach(accord => {
      addEventListenerToAccordButton(accord);
    });
  }, 10);
}

window.openNextAcordById = function(el, next){
  const nextEl = el.closest('.quote-accords-main').querySelector(`#${next}`).getElementsByTagName('button')[0];
  openNextAcord(el, nextEl);
}

function closeOtherAccords(targetAccord){
  allOpendAcrds = isAccordOpened(targetAccord);
  if( allOpendAcrds != []){
    allOpendAcrds.forEach(acrdButton => {
      $(acrdButton).click();
      acrdButton.innerText = acrdButton.dataset.innertxt; 
      acrdButton.classList.add('pb-4');
    });
  }
}

function isAccordOpened(targetAccord) {
  let mainAccordWin = targetAccord.closest('.quote-accords-main');
  let allAcordButtons = mainAccordWin.querySelectorAll('.accordion-button');

  let opendAccords = [];
  allAcordButtons.forEach(acrdButton => {
    if(acrdButton != targetAccord){
      if(!acrdButton.classList.contains('collapsed')){
        opendAccords.push(acrdButton);
      }
    }
  });
  return opendAccords;
}

document.addEventListener('DOMContentLoaded', () => {
  // quote model first accordian
  document.querySelector('#init-quote-modal').addEventListener('show.bs.modal', function(){
    setTimeout(function() {
      openNextAcord(null, document.querySelector('#panelsStayOpenLockedQuoteP-headingFive').querySelector('.accordion-button'))
    }, 10);
  });
  // locked products modal accordians
  document.querySelectorAll('.locked-product-modal').forEach(productModal => {
    productModal.addEventListener('show.bs.modal', function(){
      setTimeout(function() {
        openNextAcord(null, document.querySelector('#panelsStayOpenLockedP-headingThree').querySelector('.accordion-button'));
        productModal.querySelectorAll('.accordion-item-quote').forEach(accord => {
          addEventListenerToAccordButton(accord);
        });
      }, 10);
    });
  });
});

function addEventListenerToAccordButton(accord){
  let accordBtn = accord.getElementsByTagName('button')[0];
  accordBtn.addEventListener('click', function(){
    closeOtherAccords(accord);
    setTimeout(function() {
      if(!accordBtn.classList.contains('collapsed')){
        accordBtn.innerText = ''; 
        accordBtn.classList.remove('pb-4');
      }else{
        accordBtn.innerText = accordBtn.dataset.innertxt; 
        accordBtn.classList.add('pb-4');
      }
    }, 10);
  });
}

document.addEventListener('DOMContentLoaded', function() {
  document.querySelectorAll('.lockedp-accordian').forEach(accord => {
    addEventListenerToAccordButton(accord);
  });
});
